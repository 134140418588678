import { useGoogleAnalytics } from '@/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { Consent, GetConsentResponse } from './useConsent.types';

export const useConsent = () => {
  const ga = useGoogleAnalytics();
  const [isReady, setIsReady] = useState(false);
  const [consent, setConsent] = useState<Consent | null>(null);

  const saveConsent = (newConsent: Consent) => {
    fetch('/api/v1/consent', {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'cache-control': 'no-cache',
      },
      body: JSON.stringify(newConsent),
    }).catch(() => ({}));
  };

  const acceptAll = useCallback(() => {
    const newConsent: Consent = {
      ad_personalization: 'granted',
      ad_storage: 'granted',
      ad_user_data: 'granted',
      analytics_storage: 'granted',
    };

    ga.consent.acceptAll();
    setConsent(newConsent);
    saveConsent(newConsent);
  }, [ga]);

  const rejectAll = useCallback(() => {
    const newConsent: Consent = {
      ad_personalization: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      analytics_storage: 'denied',
    };

    ga.consent.rejectAll();
    setConsent(newConsent);
    saveConsent(newConsent);
  }, [ga]);

  const customizeConsent = useCallback(
    (newConsent: Consent) => {
      ga.consent.customizeConsents(newConsent);
      setConsent(newConsent);
      saveConsent(newConsent);
    },
    [ga]
  );

  const checkConsent = useCallback(async () => {
    const consentData: GetConsentResponse = await fetch('/api/v1/consent', {
      method: 'POST',
      headers: {
        'cache-control': 'no-cache',
      },
    })
      .then((res) => res.json())
      .catch(() => ({
        set: false,
      }));

    if (consentData.set) {
      ga.consent.customizeConsents(consentData.consent);
      setConsent(consentData.consent);
    }

    setIsReady(true);
  }, [ga.consent]);

  useEffect(() => {
    checkConsent();
  }, [checkConsent]);

  return useMemo(
    () => ({ isReady, consent, acceptAll, rejectAll, customizeConsent }),
    [isReady, consent, acceptAll, rejectAll, customizeConsent]
  );
};
