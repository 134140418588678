import { Button } from '@/components/general/Button';
import clsx from 'clsx';
import { type FC, useEffect, useState } from 'react';

import { useConsent } from './hooks';

export const ConsentBanner: FC = () => {
  const consent = useConsent();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(consent.isReady && !consent.consent);
  }, [consent]);

  return (
    <div
      className={`pointer-events-none fixed z-[9999] flex h-screen w-screen items-end justify-center transition-opacity lg:justify-start ${isVisible ? 'opacity-100' : 'opacity-0'}`}
    >
      <div
        className={`flex w-full flex-col gap-4 bg-primary px-30 pb-[calc(env(safe-area-inset-bottom)+30px)] pt-30 text-text-contrast lg:ml-9 lg:max-w-[500px] ${isVisible ? 'pointer-events-auto' : 'pointer-events-none'}`}
      >
        <h2 className="text-14 font-medium">We value your privacy</h2>

        <p className={clsx(['text-12'])}>
          We use cookies to enhance your browsing experience, serve personalized
          ads, and analyze our traffic. By clicking "Accept All", you consent to
          our use of cookies.
        </p>

        <div className="mt-2 flex flex-col gap-2 lg:flex-row [&>*]:flex-1">
          <Button
            onClick={consent.acceptAll}
            className={clsx([
              '!border-text-contrast',
              'text-primary',
              'bg-text-contrast',
              '!text-12 ',
              'hover:bg-transparent',
              'hover:text-text-contrast',
              '!py-[12px]',
              '!px-[24px]',
            ])}
          >
            Accept All
          </Button>

          <Button
            variant="outline"
            onClick={consent.rejectAll}
            className={clsx([
              'border-transparent',
              'text-text-contrast',
              '!text-10',
              'hover:border-text-contrast',
              '!py-[12px]',
              '!px-[24px]',
            ])}
          >
            Necessary Cookies Only
          </Button>
        </div>
      </div>
    </div>
  );
};
